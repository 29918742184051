import styled from '@emotion/styled';
import { colors, mediaQueries } from 'styles/variables';

export const TileWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
  // background-color: ${colors.white};
  // border-radius: 0.4rem;
  // padding: 2rem 2rem 0;
  min-height: 19.2rem;

  button {
    background: none;
    border: none;
    padding: 0;
    color: ${colors.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    > svg {
      margin-left: 10px;
    }
  }

  .tile-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-bottom: 2px solid ${colors.storm11};
    ${mediaQueries.mediumUp} {
      flex-direction: row;
    }
    &-image {
      width: 10rem;
      align-self: baseline;
      margin-bottom: 1rem;
      ${mediaQueries.mediumUp} {
        width: 12rem;
        margin-bottom: 0;
      }
    }
    &-content {
      width: 100%;
      &__title {
        font-size: 1.8rem;
        margin-bottom: 0.8rem;
      }
      &__description {
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
      ${mediaQueries.mediumUp} {
        min-height: 10.8rem;
        padding-left: 2rem;
        &__description {
          margin-bottom: 0;
        }
      }
    }
  }

  .tile-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.2rem;
    color: ${colors.dusk100};
    &__icon {
      svg {
        fill: currentColor;
      }
      margin-right: 1.2rem;
      transition-property: transform;
      transition-duration: 0.2s;
      fill: ${colors.dusk100};
      ${mediaQueries.mediumUp} {
        margin-right: 2rem;
      }
    }
    &.disabled {
      color: ${colors.storm50};
      text-decoration: none;
      cursor: not-allowed;
    }
    &:not(.disabled):hover {
      .tile-link__icon {
        ${mediaQueries.mediumUp} {
          transform: translateX(2rem);
        }
      }
    }
  }
`;
